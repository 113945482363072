<template>
  <div class="sidebar" :class="{ collapsed: isSidebarCollapsed }">
    <a
      href="/"
      class="tw-flex tw-flex-row tw-w-full tw-text-xl tw-font-medium tw-pl-3 tw-leading-loose tw-text-white logo tw-items-center tw-mb-5"
    >
      <div v-if="!isSidebarCollapsed" class="tw-flex tw-items-center">
        <span class="tw-text-blue-300 tw-mr-1">Operator</span> Dashboard
      </div>
      <div v-else><span class="tw-text-blue-300">O</span>D</div>
    </a>
    <b-button
      size="is-small"
      class="sidebar-button tw-rounded-lg"
      @click="toggleSidebar"
    >
      <ArrowToLeftIconVue
        v-if="!isSidebarCollapsed"
        type="info"
        size="16"
        class="tw-px-1"
      />
      <ArrowToRightIconVue v-else type="info" size="16px" class="tw-px-1" />
    </b-button>
    <div v-if="$auth.loggedIn" class="menu">
      <NavOperator
        v-if="$auth.user.data.role == 'operator'"
        :display-icons-only="isSidebarCollapsed"
      ></NavOperator>
      <NavManager
        v-if="$auth.user.data.role == 'manager'"
        :display-icons-only="isSidebarCollapsed"
      ></NavManager>
      <NavCoach
        v-if="$auth.user.data.role == 'coach'"
        :display-icons-only="isSidebarCollapsed"
      ></NavCoach>
      <NavTrialCoach
        v-if="$auth.user.data.role == 'trial_coach'"
        :display-icons-only="isSidebarCollapsed"
      ></NavTrialCoach>
      <NavAdmin
        v-if="$auth.user.data.role == 'admin'"
        :display-icons-only="isSidebarCollapsed"
      ></NavAdmin>
      <NavCountryManager
        v-if="$auth.user.data.role == 'country_manager'"
        :display-icons-only="isSidebarCollapsed"
      ></NavCountryManager>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import NavAdmin from '@/components/layout/main/sidebar/admin'
import NavOperator from '@/components/layout/main/sidebar/operator'
import NavManager from '@/components/layout/main/sidebar/manager'
import NavCoach from '@/components/layout/main/sidebar/coach'
import NavTrialCoach from '@/components/layout/main/sidebar/trial-coach'
import NavCountryManager from '@/components/layout/main/sidebar/country-manager'
import ArrowToLeftIconVue from '@/components/icon/ArrowToLeftIcon.vue'
import ArrowToRightIconVue from '@/components/icon/ArrowToRightIcon.vue'

export default {
  components: {
    NavAdmin,
    NavOperator,
    NavManager,
    NavCoach,
    NavTrialCoach,
    NavCountryManager,
    ArrowToLeftIconVue,
    ArrowToRightIconVue
  },

  computed: {
    ...mapGetters({ isSidebarCollapsed: 'sidebar/isSidebarCollapsed' })
  },

  methods: {
    ...mapActions({
      toggleSidebar: 'sidebar/toggleSidebar'
    })
  }
}
</script>

<style lang="scss">
.sidebar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  height: 100%;
  padding: 0;
  box-shadow: none;
  background: #0d0e12;
  transition: width 0.3s ease;

  &.collapsed {
    width: 4rem; /* Adjust width when collapsed */
  }

  .logo {
    height: 52px;
    border-bottom: 1px dashed #1f212a;
  }

  @media (min-width: 1024px) {
    width: 16.5625rem;
    display: block;
  }

  .menu {
    overflow-y: auto;
    overflow-x: hidden;

    &-list {
      li {
        ul {
          border-left-color: hsl(221, 14%, 24%);
          margin-left: 1rem;
        }
      }
      a {
        padding: 0.65rem 1rem;
        color: #9a9cae;

        &.nuxt-link-exact-active,
        &.tw-text-white {
          .svg-icon {
            svg {
              g {
                [fill] {
                  fill: #ffffff;
                }
              }
            }
          }
        }

        .svg-icon {
          svg {
            g {
              [fill] {
                fill: #464852;
              }
            }
          }
        }

        &:hover {
          svg {
            g {
              [fill] {
                fill: #ffffff;
              }
            }
          }
        }

        .tag {
          font-size: 12px !important;
        }
      }
    }
  }

  &-button {
    position: absolute;
    top: 0.65rem;
    right: -1.2rem;
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
