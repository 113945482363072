import AccountRequest from '@/models/AccountRequest'

export const state = () => ({
  pending_account_requests: {
    data: [],
    meta: {
      total: 0
    }
  }
})

export const mutations = {
  SET_PENDING_ACCOUNT_REQUESTS(state, pending_account_requests) {
    state.pending_account_requests.data = pending_account_requests.data
    state.pending_account_requests.meta = pending_account_requests.meta
  }
}

export const getters = {
  getPendingAccountRequests(state) {
    return state.pending_account_requests
  }
}

export const actions = {
  async fetchPendingRequests({ commit }) {
    await AccountRequest.where('byStatus', 'Pending')
      .get()
      .then(response => {
        commit('SET_PENDING_ACCOUNT_REQUESTS', response)
      })
      .catch(error => {
        console.error('Error with fetching pending account requests', error)
      })
  }
}
