<template>
  <ul class="menu-list">
    <li>
      <nuxt-link
        to="/"
        active-class="tw-text-white"
        exact
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <BlocksIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Dashboard</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/messages"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <MessageIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Messages</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Trigger')"
        to="/triggers"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <TriggerIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Triggers</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Profile')"
        to="/profiles"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <GroupIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Profiles</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Operator')"
        to="/my-team"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <GroupIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">My Team</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Invoice')"
        to="/invoices"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <FileIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Invoices</span>
      </nuxt-link>
    </li>

    <li>
      <a
        class="has-dropdown tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center tw-justify-between"
        @click="toggleSubmenu('duplicate')"
      >
        <div class="tw-flex tw-items-center">
          <DuplicateIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">Duplicate</span>
        </div>

        <b-icon icon="chevron-down" size="is-small"></b-icon>
      </a>
      <ul v-show="displayedSubmenus.duplicate">
        <li>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/39"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Dupe CA
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/231"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Dupe UK2
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 232"
            to="/profile-duplicates/232"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Dupe ZA
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/230"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Dupe US
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/234"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Dupe AU
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            v-if="$can('list', 'Invoice') && $auth.user.data.country_id == 229"
            to="/profile-duplicates/235"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Dupe NZ
          </nuxt-link>
        </li>
      </ul>
    </li>
    <li>
      <a
        :class="{ 'tw-text-white': submenus.resources }"
        class="has-dropdown tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center tw-justify-between tw-relative"
        @click="toggleSubmenu('resources')"
      >
        <div class="tw-flex tw-items-center tw-relative">
          <FolderIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">Resources</span>
          <div
            v-if="newsCount > 0 || faqViews.length > 0"
            class="tw-flex tw-absolute tw-h-3 tw-w-3 tw-top-0 tw-left-0"
          >
            <span
              class="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400  tw-opacity-50"
            ></span>
            <span
              class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-600"
            ></span>
          </div>
        </div>
        <b-icon icon="chevron-down" size="is-small"></b-icon>
      </a>
      <ul v-show="displayedSubmenus.resources">
        <li>
          <nuxt-link
            to="/news"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            News
            <div
              v-if="newsCount > 0"
              class="tw-bg-red-600 tw-rounded-lg tw-ml-2 tw-text-xs tw-px-1 tw-flex tw-items-center tw-justify-center tw-text-white tw-leading-normal"
            >
              {{ newsCount }} new
            </div>
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/manuals"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Manuals
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/rejection-categories"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Rejection Categories
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/faqs"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center tw-relative"
          >
            FAQs
            <div
              v-if="faqViews.length"
              class="tw-bg-red-600 tw-rounded-lg tw-ml-2 tw-text-xs tw-px-1 tw-flex tw-items-center tw-justify-center tw-text-white tw-leading-normal"
            >
              {{ faqViews.length }} new
            </div>
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/traffic-stats"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Traffic Stats
          </nuxt-link>
        </li>
      </ul>
    </li>
    <li>
      <a
        class="has-dropdown tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center tw-justify-between"
        @click="toggleSubmenu('stats')"
      >
        <div class="tw-flex tw-items-center">
          <LineChartIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">Stats</span>
        </div>
        <b-icon icon="chevron-down" size="is-small"></b-icon>
      </a>
      <ul v-show="displayedSubmenus.stats">
        <li>
          <nuxt-link
            to="/feedback-funnel"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Feedback Funnel
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/pl-stats"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            PL Stats
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/wink-replies"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Wink Replies Stats
          </nuxt-link>
        </li>
      </ul>
    </li>
    <li>
      <a
        class="has-dropdown tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center tw-justify-between"
        @click="toggleSubmenu('logs')"
      >
        <div class="tw-flex tw-items-center tw-relative">
          <FileThunderIcon class="tw-mr-2" />
          <span v-if="!displayIconsOnly">Logs</span>
          <div
            v-if="pendingRequests.data.length"
            class="tw-flex tw-absolute tw-h-3 tw-w-3 tw-top-0 tw-left-0"
          >
            <span
              class="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400  tw-opacity-50"
            ></span>
            <span
              class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-600"
            ></span>
          </div>
        </div>
        <b-icon icon="chevron-down" size="is-small"></b-icon>
      </a>

      <ul v-show="displayedSubmenus.logs">
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/journal-edits"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Journal Edits
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/lock-logs"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Lock Logs
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/pay-rate"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Pay Rate
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/payment-updates"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Payment Updates
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/removed-accounts"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Removed Accounts
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/added-accounts"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Added Accounts
          </nuxt-link>
        </li>
        <li class="tw-text-manatee">
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/account-requests"
            active-class="tw-text-white"
            class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
          >
            Account Requests
            <div
              v-if="pendingRequests.data.length"
              class="tw-bg-red-600 tw-rounded-lg tw-ml-2 tw-text-xs tw-px-1 tw-flex tw-items-center tw-justify-center tw-text-white tw-leading-normal"
            >
              {{ pendingRequests.meta.total }} waiting
            </div>
          </nuxt-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MessageIcon from '@/components/icon/MessageIcon.vue'
import TriggerIcon from '@/components/icon/TriggerIcon.vue'
import GroupIcon from '@/components/icon/GroupIcon.vue'
import FileIcon from '@/components/icon/FileIcon.vue'
import BlocksIcon from '@/components/icon/BlocksIcon.vue'
import DuplicateIcon from '@/components/icon/DuplicateIcon.vue'
import FolderIcon from '@/components/icon/FolderIcon.vue'
import LineChartIcon from '@/components/icon/LineChartIcon.vue'
import FileThunderIcon from '@/components/icon/FileThunderIcon.vue'

export default {
  components: {
    MessageIcon,
    TriggerIcon,
    GroupIcon,
    FileIcon,
    BlocksIcon,
    DuplicateIcon,
    FolderIcon,
    LineChartIcon,
    FileThunderIcon
  },

  props: {
    displayIconsOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submenus: {
        logs: false,
        duplicate: false,
        resources: false,
        stats: false
      }
    }
  },

  computed: {
    ...mapGetters({
      getFaqViews: 'faq-views/getFaqViews',
      isSidebarCollapsed: 'sidebar/isSidebarCollapsed',
      getPendingAccountRequests:
        'pending-account-requests/getPendingAccountRequests'
    }),
    displayedSubmenus() {
      if (this.isSidebarCollapsed) {
        let collapsedSubmenus = {}
        for (const key in this.submenus) {
          collapsedSubmenus[key] = false
        }
        return collapsedSubmenus
      } else {
        return this.submenus
      }
    },
    faqViews() {
      return this.getFaqViews
    },
    pendingRequests() {
      return this.getPendingAccountRequests
    },
    newsCount() {
      return this.$store.state.news.news_count
    }
  },
  mounted() {
    this.fetchFaqViews()
    this.fetchPendingRequests()
  },
  methods: {
    toggleSubmenu(submenu) {
      for (const key in this.submenus) {
        if (key !== submenu) {
          this.submenus[key] = false
        }
      }

      this.submenus[submenu] = !this.submenus[submenu]
      this.setSidebar(false)
    },
    ...mapActions({
      fetchFaqViews: 'faq-views/fetchFaqViews',
      setSidebar: 'sidebar/setSidebar',
      fetchPendingRequests: 'pending-account-requests/fetchPendingRequests'
    })
  }
}
</script>
