import Model from './Model'

export default class AccountRequest extends Model {
  resource() {
    return 'operator/account-requests'
  }

  get processed_at() {
    return this.approved_at ?? this.rejected_at
  }

  get status() {
    if (this.processed_by_id) {
      return this.approved_at ? 'APPROVED' : 'REJECTED'
    } else {
      return 'PENDING'
    }
  }
}
