import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _11474598 = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _6d5b3de7 = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _2bdb83e5 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _f7fa448c = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _2dc56669 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _1c3ed29a = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _07850e2d = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _4743b644 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _59dee73b = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _3962e194 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _24c21959 = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _f403c558 = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _5c89492c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4f11f976 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _4f717cf3 = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _78d275fc = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _64988659 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _05cf6fb6 = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _aacf57b8 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _02ad553a = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _20e03c2b = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _366c99f4 = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _01b7b3de = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _5a8884ac = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _d64ab98c = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _2d51eab0 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _08328033 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _68698865 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _7f58be7a = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _3bc17595 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _5b71f06a = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _4b8b422b = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _34b77bcc = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _927415fe = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _160d6a1a = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _d1df782e = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _33fdfda2 = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _533f25be = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _7e6b2ecc = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _2e2e0ec4 = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _0c09b502 = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _7f07d9ac = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _5470adfe = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _3d464788 = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _e43eac60 = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _6c51a270 = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _979b0644 = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _81b14e8c = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _6d85b3be = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _45ecaad0 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _efcca6c6 = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _2828f796 = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _dd060336 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _4d1e44e0 = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _1586c5b4 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _888baf52 = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _6199f164 = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _f4199828 = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _14bcee86 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _4a66ea2e = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _da3cb3d6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _11474598,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _6d5b3de7,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _2bdb83e5,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _f7fa448c,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _2dc56669,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _1c3ed29a,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _07850e2d,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _4743b644,
    name: "feedback-funnel"
  }, {
    path: "/ht-alerts",
    component: _59dee73b,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _3962e194,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _24c21959,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _f403c558,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _5c89492c,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _4f11f976,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _4f717cf3,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _78d275fc,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _64988659,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _05cf6fb6,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _aacf57b8,
    name: "my-team"
  }, {
    path: "/news",
    component: _02ad553a,
    name: "news"
  }, {
    path: "/notifications",
    component: _20e03c2b,
    name: "notifications"
  }, {
    path: "/payment-updates",
    component: _366c99f4,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _01b7b3de,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _5a8884ac,
    name: "profile"
  }, {
    path: "/profiles",
    component: _d64ab98c,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _2d51eab0,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _08328033,
    name: "settings"
  }, {
    path: "/stats",
    component: _68698865,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _7f58be7a,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _3bc17595,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _5b71f06a,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _4b8b422b,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _34b77bcc,
    name: "wink-replies"
  }, {
    path: "/manuals/new",
    component: _927415fe,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _160d6a1a,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _d1df782e,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _33fdfda2,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _533f25be,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _7e6b2ecc,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _2e2e0ec4,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _0c09b502,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _7f07d9ac,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _5470adfe,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _3d464788,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _e43eac60,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _6c51a270,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/account-requests/:request?",
    component: _979b0644,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _81b14e8c,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _6d85b3be,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _45ecaad0,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _efcca6c6,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _2828f796,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _dd060336,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _4d1e44e0,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _1586c5b4,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _888baf52,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _6199f164,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _f4199828,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _14bcee86,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _4a66ea2e,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _da3cb3d6,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
