<template>
  <div v-if="$auth.user" class="navbar-start">
    <nuxt-link
      to="/messages"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Messages
    </nuxt-link>
    <nuxt-link
      to="/news"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      News
    </nuxt-link>
    <nuxt-link
      v-if="$can('list', 'Operator')"
      to="/my-team"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      My Team
    </nuxt-link>
    <div class="navbar-item tw-text-left is-hidden-desktop">
      <b-dropdown position="is-bottom-left" disabled>
        <template #trigger>
          <span
            class="tw-font-medium tw-text-washed hover:tw-text-black tw-cursor-not-allowed tw-opacity-25"
            role="button"
          >
            Logs
            <i class="icon-arrow-down tw-text-sm"></i>
          </span>
        </template>

        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/lock-logs"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Lock Logs
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/pay-rate"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Pay Rate
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/payment-updates"
            active-class="is-active"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Payment Updates
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/removed-accounts"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Removed Accounts
          </nuxt-link>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <nuxt-link
            v-if="$can('list', 'Operator')"
            to="/activity-logs/added-accounts"
            class="tw-font-medium tw-text-washed hover:tw-text-black"
          >
            Added Accounts
          </nuxt-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <nuxt-link
      to="/faqs"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      FAQs
      <b-icon
        v-if="faqViews.length"
        icon="bell"
        class="tw-ml-1"
        style="color: #fc406c"
      ></b-icon>
    </nuxt-link>
    <nuxt-link
      to="/bonus"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Bonus
    </nuxt-link>
    <nuxt-link
      to="/message-reports"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
    >
      Message Reports
    </nuxt-link>
    <span
      class="navbar-item tw-text-left tw-flex tw-items-center tw-content-center"
    >
      <OfflineButton />
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OfflineButton from '../../operator/operator-offline-button.vue'

export default {
  components: {
    OfflineButton
  },
  data() {
    return {
      isOnMessagePage: this.$route.path === '/messages'
    }
  },
  computed: {
    ...mapGetters({
      getFaqViews: 'faq-views/getFaqViews'
    }),
    faqViews() {
      return this.getFaqViews
    }
  },
  watch: {
    $route(to, from) {
      this.isOnMessagePage = to.path === '/messages'
    }
  },
  mounted() {
    this.fetchFaqViews()
  },
  methods: {
    ...mapActions({
      fetchFaqViews: 'faq-views/fetchFaqViews'
    })
  }
}
</script>
